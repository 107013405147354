<div
  class="absolute left-0 top-0 z-10 flex w-full justify-end border-b border-solid border-gray-200 bg-white p-2"
>
  <lu-folder-list-multi-action-toolbar
    class="w-full"
    (closed)="toolbarClosed()"
    (moved)="refreshTree()"
  />
  <button
    mat-icon-button
    matTooltip="Select Pathways/Skills"
    matTooltipShowDelay="300"
    [color]="selectMode ? 'primary' : ''"
    (click)="selectMode = !selectMode"
  >
    <mat-icon>check_box</mat-icon>
  </button>

  <ng-container *luIsAdmin>
    <button
      mat-icon-button
      matTooltip="Add New Folder"
      matTooltipShowDelay="300"
      (click)="addNewFolder()"
    >
      <mat-icon>create_new_folder</mat-icon>
    </button>
  </ng-container>

  <button
    mat-icon-button
    matTooltip="Collapse All Folders"
    (click)="collapseAllFolders()"
  >
    <mat-icon>close_fullscreen</mat-icon>
  </button>
</div>

<mat-tree class="mt-12" [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <lu-folder-leaf-node class="min-w-0 grow" [node]="node">
      <mat-checkbox
        color="primary"
        [style.display]="
          selectMode && node.parentType === 'folder' && node.type !== 'folder'
            ? 'inline'
            : 'none'
        "
        [checked]="
          node | map: selectionList.isSelected$ : selectionList | async
        "
        (change)="toggleSelected(node)"
      />
    </lu-folder-leaf-node>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="group/item node-row relative flex w-full grow">
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <div class="ml-2 flex grow items-center gap-4 truncate">
        <mat-checkbox
          color="primary"
          [style.display]="
            selectMode && node.parentType === 'folder' && node.type !== 'folder'
              ? 'inline'
              : 'none'
          "
          (change)="toggleSelected(node)"
        />

        <mat-icon class="shrink-0">{{ node.icon }}</mat-icon>

        <ng-container *ngIf="node.url; else simpleLabel">
          <a
            [routerLink]="[node.url]"
            class="truncate"
            [ptTruncateTooltip]="node.name"
          >
            {{ node.name }}
          </a>
        </ng-container>

        <ng-template #simpleLabel>
          <span class="truncate" [ptTruncateTooltip]="node.name">{{
            node.name
          }}</span>
        </ng-template>

        <mat-progress-spinner
          *ngIf="node.isLoading && treeControl.isExpanded(node)"
          diameter="20"
          mode="indeterminate"
        />

        <ng-container *ngIf="node | map: isFolder">
          <div
            class="group/edit actions invisible absolute right-0 top-0 z-10 ml-auto flex bg-white group-hover/item:visible"
          >
            <button
              mat-icon-button
              matTooltip="Add New Skill"
              matTooltipShowDelay="300"
              (click)="addNewSkill(node.ref)"
            >
              <mat-icon>post_add</mat-icon>
            </button>
            <ng-container *luIsAdmin>
              <button
                *luIsAdmin
                mat-icon-button
                matTooltip="Add New Pathway"
                matTooltipShowDelay="300"
                (click)="addNewPathway(node.ref)"
              >
                <mat-icon>fact_check</mat-icon>
              </button>
              <button
                *ngIf="node.canAddFolder"
                mat-icon-button
                matTooltip="Add New Folder"
                matTooltipShowDelay="300"
                (click)="addNewFolder(node.ref)"
              >
                <mat-icon>create_new_folder</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Edit Folder"
                matTooltipShowDelay="300"
                (click)="editFolder(node.ref)"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="node | map: isPathway">
          <ng-container *luIsAdmin>
            <div
              class="group/edit actions invisible ml-auto flex bg-white group-hover/item:visible"
            >
              <lu-favourite-indicator [item]="node.pathway" />
              <button
                [routerLink]="['/admin/pathways', node.ref.id]"
                mat-icon-button
                matTooltip="Edit Pathway"
                matTooltipShowDelay="300"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div
      [class.example-tree-invisible]="!treeControl.isExpanded(node)"
      role="group"
    >
      <ng-container matTreeNodeOutlet />
    </div>
  </mat-nested-tree-node>
</mat-tree>
