<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  [matTooltip]="tooltip"
  [ngClass]="{ compact: compact }"
>
  <mat-icon>filter_list</mat-icon>
  <div *ngIf="displayBadge" class="has-notification"></div>
</button>

<mat-menu #menu="matMenu">
  <mat-selection-list (selectionChange)="updateSelected($event)">
    <ng-container
      *ngFor="let filter of statusFilters$ | async; trackBy: trackByFilter"
    >
      <mat-list-option
        #listOption="matListOption"
        checkboxPosition="before"
        [value]="filter.id"
        [selected]="filter.id | inArray$: activeStatuses$ | async"
        (click)="$event.stopPropagation()"
      >
        <span>{{
          formatLabel ? (filter.label | splitCamel | titlecase) : filter.label
        }}</span>
      </mat-list-option>
    </ng-container>
  </mat-selection-list>
</mat-menu>
