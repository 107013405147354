import {
  Component,
  ElementRef,
  ViewChild,
  type OnDestroy,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { type MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  InlineNodes,
  NodeGroup,
  findNodePosition,
  findNodeRange,
  type IMention,
  fromEditorEvents,
} from '@principle-theorem/editor';
import {
  EditorNode,
  EditorNodeComponent,
  IRenderHTMLArguments,
  NodeAttribute,
  type IConfigurable,
  type IHasUid,
} from '@principle-theorem/ng-prosemirror';
import {
  OptionGroupSearchFilter,
  TrackByFunctions,
  type IOptionGroup,
} from '@principle-theorem/ng-shared';
import { shareReplayCold } from '@principle-theorem/shared';
import { DOMOutputSpec, type Fragment } from '@tiptap/pm/model';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  from,
  noop,
  type Observable,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { EditorAutocompleteTriggerDirective } from '../../../editor-autocomplete/editor-autocomplete-trigger.directive';
import { MENTION_KEYMAP } from '../mention-keymap';
import { Editor } from '@tiptap/core';

export type IMentionOptionGroup = IOptionGroup<IMention>;

export interface IMentionAutocompleteNodeConfig {
  mentions$: (query$: Observable<string>) => Observable<IMentionOptionGroup[]>;
  suggestionWidth?: string | number;
}

@EditorNode({
  name: InlineNodes.MentionAutocomplete,
  content: `${InlineNodes.Text}*`,
  group: NodeGroup.Inline,
  inline: true,
  defining: true,
})
@Component({
  selector: 'pt-mention-autocomplete-node',
  templateUrl: './mention-autocomplete-node.component.html',
  styleUrls: ['./mention-autocomplete-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionAutocompleteNodeComponent
  extends EditorNodeComponent<IHasUid>
  implements IHasUid, OnDestroy, IConfigurable<IMentionAutocompleteNodeConfig>
{
  private _onDestroy$: Subject<void> = new Subject();
  // private _keydownListener: EventListener;
  trackByGroup = TrackByFunctions.field<IMentionOptionGroup>('name');
  trackByMention = TrackByFunctions.uniqueId<IMention>();
  override selected$ = new BehaviorSubject<boolean>(true);
  content$: Observable<string>;
  disabled$: Observable<boolean>;
  autocompleteTrigger$: ReplaySubject<EditorAutocompleteTriggerDirective> =
    new ReplaySubject(1);
  searchFilter: OptionGroupSearchFilter<IMention>;
  suggestionWidth: string | number = '240px';
  mentions$: ReplaySubject<IMentionOptionGroup[]> = new ReplaySubject(1);
  @NodeAttribute() @Input() uid: string = uuid();

  constructor(elementRef: ElementRef) {
    super(elementRef);

    this.content$ = this.editor$.pipe(
      switchMap(fromEditorEvents),
      switchMap(() =>
        this.node$.pipe(
          map((node) => node.textContent),
          map((content) =>
            content.startsWith(MENTION_KEYMAP) ? content.substring(1) : content
          )
        )
      ),
      shareReplayCold()
    );
    this.searchFilter = new OptionGroupSearchFilter<IMention>(
      this.mentions$,
      this.content$,
      ['key']
    );

    this.disabled$ = combineLatest([
      this.editor$.pipe(map((editor) => editor.isEditable)),
      this.selected$,
    ]).pipe(map(([isEditable, selected]) => !isEditable || !selected));

    this.event$
      .pipe(
        filter((event): event is MouseEvent => event instanceof MouseEvent),
        withLatestFrom(this.autocompleteTrigger$, this.selected$),
        filter(([_event, _autocompleteTrigger, selected]) => selected),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([event, autocompleteTrigger, _selected]) => {
        autocompleteTrigger.addMouseEvent(event);
      });

    this.event$
      .pipe(
        filter(
          (event): event is KeyboardEvent => event instanceof KeyboardEvent
        ),
        withLatestFrom(this.autocompleteTrigger$, this.editor$, this.selected$),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([event, autocompleteTrigger, editor, _]) => {
        if (this._shouldRemoveMention(event, editor)) {
          return this.removeMention();
        }

        if (event.key === 'Enter') {
          if (!autocompleteTrigger.activeOption) {
            return this.removeMention();
          }
          event.stopPropagation();
        }
        autocompleteTrigger.addKeyboardEvent(event);
      });

    this.selected$
      .pipe(
        withLatestFrom(this.autocompleteTrigger$),
        tap(([selected, autocompleteTrigger]) =>
          selected
            ? from(autocompleteTrigger.openPanel())
            : autocompleteTrigger.closePanel()
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(noop);
  }

  configure(config: IMentionAutocompleteNodeConfig): void {
    config
      .mentions$(this.content$)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((mentions) => this.mentions$.next(mentions));
    if (config.suggestionWidth) {
      this.suggestionWidth = config.suggestionWidth;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @ViewChild(EditorAutocompleteTriggerDirective, { static: false })
  set autocompleteTrigger(
    autocompleteTrigger: EditorAutocompleteTriggerDirective
  ) {
    if (autocompleteTrigger) {
      this.autocompleteTrigger$.next(autocompleteTrigger);
    }
  }

  selectMention($event: MatAutocompleteSelectedEvent): void {
    const mention = $event.option.value as IMention;
    const mentionType =
      this.editor.view.state.schema.nodes[InlineNodes.Mention];

    const nodePosition = findNodePosition(
      this.editor.view.state.doc,
      this.node
    );
    if (!nodePosition) {
      return;
    }
    const fromPos = nodePosition.pos;
    const toPos = nodePosition.pos + this.node.nodeSize;
    this.editor.view.dispatch(
      this.editor.view.state.tr.replaceWith(
        fromPos,
        toPos,
        mentionType.create({
          key: mention.key,
          path: mention.resource.ref.path,
          type: mention.resource.type,
        })
      )
    );
  }

  removeMention(): void {
    const nodePosition = findNodeRange(this.editor.view.state.doc, this.node);
    if (!nodePosition) {
      return;
    }

    const childNodes: Fragment = this.node.content.cut(
      0,
      this.node.content.size
    );

    this.editor.view.dispatch(
      this.editor.view.state.tr.replaceWith(
        nodePosition.$from.before(),
        nodePosition.$to.after(),
        childNodes
      )
    );
  }

  renderHTML(data: IRenderHTMLArguments): DOMOutputSpec {
    return ['span', data.HTMLAttributes];
  }

  private _shouldRemoveMention(event: KeyboardEvent, editor: Editor): boolean {
    const lastChar = this._getCharBeforeCursor(editor, -1);
    const beforeLastChar = this._getCharBeforeCursor(editor, -2);

    const isSpaceAfterMention =
      event.code === 'Space' &&
      beforeLastChar === MENTION_KEYMAP &&
      lastChar === '';

    const isDoubleSpace = event.code === 'Space' && lastChar === ' ';
    return isSpaceAfterMention || isDoubleSpace || event.key === 'Escape';
  }

  private _getCharBeforeCursor(editor: Editor, offset: number): string {
    const { from: selectionFrom } = editor.view.state.selection;

    return editor.view.state.doc.textBetween(
      selectionFrom + offset,
      selectionFrom + offset + 1
    );
  }
}
