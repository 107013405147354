import { findKey } from 'lodash';

const ALLOWED_IMAGE_TYPES = [
  'image/bmp',
  'image/x-ms-bmp',
  'image/gif',
  'image/vnd.microsoft.icon',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
];

const ALLOWED_AUDIO_TYPES = [
  'audio/aac',
  'audio/midi',
  'audio/x-midi',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
  'audio/3gpp',
  'audio/3gpp2',
  'audio/mp3',
];

const ALLOWED_VIDEO_TYPES = [
  'video/x-msvideo',
  'video/mpeg',
  'video/ogg',
  'video/mp2t',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
  'video/mp4',
  'video/quicktime',
];

const ALLOWED_IFRAME_TYPES = ['application/pdf'];

const ALLOWED_DOC_VIEWER_TYPES = [
  'application/msword',
  'application/postscript',
  'application/vnd.apple.pages',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.rar',
  'application/x-httpd-php',
  'application/zip',
  'font/ttf',
  'image/svg+xml',
  'image/tiff',
  'image/vnd.adobe.photoshop',
  'model/vnd.dwf',
  'text/css',
  'text/csv',
  'text/html',
  'text/javascript',
  'text/plain',
];

export enum FileDisplayType {
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  IFrame = 'iframe',
  DocViewer = 'docViewer',
  Unsupported = 'unsupported',
}

const DISPLAY_TYPE_MAP: Record<FileDisplayType, string[]> = {
  [FileDisplayType.Image]: ALLOWED_IMAGE_TYPES,
  [FileDisplayType.Audio]: ALLOWED_AUDIO_TYPES,
  [FileDisplayType.Video]: ALLOWED_VIDEO_TYPES,
  [FileDisplayType.IFrame]: ALLOWED_IFRAME_TYPES,
  [FileDisplayType.DocViewer]: ALLOWED_DOC_VIEWER_TYPES,
  [FileDisplayType.Unsupported]: [],
};

const MIMETYPE_TRANSFORM_MAP: Record<string, string> = {
  'video/quicktime': 'video/mp4',
};

export function getTransformedMimeType(mimeType: string): string {
  return MIMETYPE_TRANSFORM_MAP[mimeType] || mimeType;
}

export function getFileDisplayType(contentType: string): FileDisplayType {
  const displayType = findKey(DISPLAY_TYPE_MAP, (contentTypes) =>
    contentTypes.includes(contentType)
  ) as FileDisplayType | undefined;
  return displayType || FileDisplayType.Unsupported;
}
