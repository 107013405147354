<form [formGroup]="dateRange" fxLayout="row wrap" fxLayoutGap="16px grid">
  <mat-form-field [appearance]="appearance" subscriptSizing="dynamic">
    <mat-label>Select a predefined date</mat-label>
    <mat-select
      placeholder="Predefined Dates"
      formControlName="predefinedDate"
      (selectionChange)="updatePredefinedDate($event)"
    >
      <mat-option
        *ngFor="let predefinedDate of predefinedDates; trackBy: trackByDate"
        [value]="predefinedDate"
      >
        {{ predefinedDate.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="!useRangePicker">
    <mat-form-field [appearance]="appearance" subscriptSizing="dynamic">
      <mat-label>{{ startDateLabel }}</mat-label>
      <input
        matInput
        formControlName="from"
        [matDatepicker]="fromInput"
        [max]="dateRange.value.to"
        (focus)="fromInput.open()"
        (click)="fromInput.open()"
      />
      <mat-datepicker-toggle matIconSuffix [for]="fromInput" />
      <mat-datepicker #fromInput />
    </mat-form-field>
    <mat-form-field [appearance]="appearance" subscriptSizing="dynamic">
      <mat-label>{{ endDateLabel }}</mat-label>
      <input
        matInput
        formControlName="to"
        [matDatepicker]="toInput"
        [min]="dateRange.value.from"
        (focus)="toInput.open()"
        (click)="toInput.open()"
      />
      <mat-datepicker-toggle matIconSuffix [for]="toInput" />
      <mat-datepicker #toInput />
    </mat-form-field>
  </ng-container>

  <mat-form-field
    [appearance]="appearance"
    *ngIf="useRangePicker"
    subscriptSizing="dynamic"
  >
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="rangePicker">
      <input matStartDate placeholder="Start date" formControlName="from" />
      <input matEndDate placeholder="End date" formControlName="to" />
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="rangePicker" />
    <mat-date-range-picker #rangePicker />
  </mat-form-field>
</form>
